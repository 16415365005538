(function ($, root, undefined) {
	
	$(function () {
		
		'use strict';
		
		if ($(window).width() < 768) {
			$('li.menu-item-has-children').one("click", function(e){
				e.preventDefault();
				$('li.menu-item-has-children .sub-menu').slideToggle();
			});
		}
		
		$('.mobMenuTrig').click(function(){
			
			$('.mobMenuTrig svg').toggleClass('fa-bars , fa-times');
			$('#mob-menu').toggleClass('active');
			
		});
		
	});
	
})(jQuery, this);
